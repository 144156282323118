import { create } from "zustand";

type BreadcrumbsStore = {
    name: string | null;
    setName: (name: string) => void;
    /** @deprecated will get replaced by name */
    currentLabelOverride: string | null;
    setCurrentLabelOverride: (label: string) => void;
};

export const useBreadcrumbsStore = create<BreadcrumbsStore>()((set) => ({
    name: null,
    setName: (name: string) => {
        set({ name });
    },
    currentLabelOverride: null,
    setCurrentLabelOverride: (label: string) => {
        set({ currentLabelOverride: label });
    },
}));
