import { useGetTenantsForUserQuery } from "@bespeak/apollo";

export function useGetTenantsForUser() {
    const { data, loading } = useGetTenantsForUserQuery({
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    const tenants = data?.getTenantsForUser ?? [];

    return {
        loading,
        tenants,
    };
}
