import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@/components/organisms/Breadcrumbs";
import Tabs from "./general-settings-tabs";

const GeneralSettingsLayout = () => {
    const { t } = useTranslation("common", {
        keyPrefix: "general-settings",
    });

    return (
        <Stack
            p={3}
            spacing={2}
            maxWidth={(theme) => theme.breakpoints.values.sm}
            width="100%"
        >
            <Breadcrumbs />
            <Typography component="h1" variant="h5">
                {t("title")}
            </Typography>
            <Tabs />
            <Outlet />
        </Stack>
    );
};

export default GeneralSettingsLayout;
