import { type MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import CurrentUser from "../CurrentUser/CurrentUser";
import useKeycloak from "@/contexts/Keycloak/useKeycloak";
import noop from "@/lib/noop";
import { useQuery } from "@apollo/client";
import { GetAuthenticatedUserDocument } from "@bespeak/apollo";
import { Divider } from "@mui/material";
import useTenantToken from "@/contexts/Tenant/useTenantToken";
import useTenantName from "@/contexts/Tenant/useTenantName";

const Null = () => null;

export const AccountMenu = () => {
    const { data: { getAuthenticatedUser } = {} } = useQuery(
            GetAuthenticatedUserDocument,
        ),
        firstName = getAuthenticatedUser?.firstName,
        lastName = getAuthenticatedUser?.lastName,
        emailAddress = getAuthenticatedUser?.emailAddress;
    const { t } = useTranslation("common", {
        keyPrefix: "account-menu",
    });

    const [, setToken] = useTenantToken();
    const [, setTenantName] = useTenantName();

    const { client: keycloak } = useKeycloak();

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOnMenuItemClick = (handlerFunction: () => void) => () => {
        handlerFunction();
        handleClose();
    };

    const menuItems = [
        [
            [
                Null,
                <div key="current-user">
                    <div>
                        <strong>{[firstName, lastName].join(" ")}</strong>
                    </div>
                    <div>
                        <span>
                            <small>{emailAddress}</small>
                        </span>
                    </div>
                </div>,
                handleOnMenuItemClick(noop),
            ],
        ],
        [
            [
                PersonIcon,
                t("my-information"),
                handleOnMenuItemClick(() => {
                    navigate("/account/personal-information");
                }),
            ],
        ],
        [
            [
                LogoutIcon,
                t("log-out"),
                handleOnMenuItemClick(() => {
                    keycloak.logout();
                    setToken(null);
                    setTenantName(null);
                }),
            ],
        ],
    ] as const;

    //
    // TODO: Fix backend endpoint to support saving user information
    // See: https://tribeagency.atlassian.net/browse/BW-1109
    //
    const accountMenuItems = menuItems.filter(
        (menuGroup, index) => index !== 1,
    );

    return (
        <Box>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
                <CurrentUser />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {accountMenuItems.map((menuGroup, index) => [
                    menuGroup.map(([Icon, key, onClickHandler], itemIndex) => (
                        <MenuItem
                            key={itemIndex}
                            onClick={onClickHandler}
                            {...(index === 0
                                ? { sx: { pointerEvents: "none" } }
                                : {})}
                        >
                            <Icon sx={{ mr: 1 }} />
                            {key}
                        </MenuItem>
                    )),
                    index < accountMenuItems.length - 1 && <Divider />,
                ])}
            </Menu>
        </Box>
    );
};

export default AccountMenu;
