import type { UIMatch } from "react-router-dom";
import { Link, useMatches } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import MuiBreadcrumbsLink from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import { useBreadcrumbsStore } from "@/store/breadcrumbs";

export const Breadcrumbs = () => {
    const { t } = useTranslation("common", {
        keyPrefix: "breadcrumbs",
    });

    const matches = useMatches();

    const name = useBreadcrumbsStore((state) => state.name);

    const crumbs = matches
        .filter(
            (
                match,
            ): match is UIMatch<
                unknown,
                {
                    crumb: (name?: string | null) => string;
                    noTranslate?: boolean;
                }
            > =>
                "handle" in match &&
                typeof match == "object" &&
                match.handle != null &&
                typeof match.handle === "object" &&
                "crumb" in match.handle &&
                typeof match.handle.crumb === "function",
        )
        .map((match, _, arr) => {
            return {
                ...match,
                isCurrent: match === arr.at(-1),
                crumb: match.handle.crumb(name),
            };
        });

    const { currentLabelOverride } = useBreadcrumbsStore();

    return (
        <MuiBreadcrumbs aria-label="breadcrumb">
            <MuiBreadcrumbsLink
                component={Link}
                underline="hover"
                color="inherit"
                to="/"
                sx={{ display: "flex", alignItems: "center" }}
            >
                <HomeIcon fontSize="inherit" />
            </MuiBreadcrumbsLink>
            {crumbs.map((crumb) => (
                <MuiBreadcrumbsLink
                    key={crumb.pathname}
                    component={crumb.isCurrent ? "span" : Link}
                    underline={crumb.isCurrent ? "none" : "hover"}
                    color="inherit"
                    to={crumb.pathname}
                    sx={{ display: "flex", alignItems: "center" }}
                >
                    {crumb.isCurrent && currentLabelOverride
                        ? currentLabelOverride
                        : crumb.handle.noTranslate
                          ? crumb.crumb
                          : t(crumb.handle?.crumb())}
                </MuiBreadcrumbsLink>
            ))}
        </MuiBreadcrumbs>
    );
};
