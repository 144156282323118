import type { ReactNode } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import type { TenantContextValue } from "./TenantContext";
import TenantContext from "./TenantContext";
import {
    IssueTenantTokenDocument,
    GetTenantByNameDocument,
    type IssueTenantTokenMutation,
} from "@bespeak/apollo";
import useAuthenticatedClient from "@/lib/apollo/useAuthenticatedClient";
import useTenantName from "./useTenantName";
import { jwtDecode } from "jwt-decode";
import useTenantToken from "@/contexts/Tenant/useTenantToken";
import { useInterval } from "usehooks-ts";

export interface TenantProviderProps {
    children: ReactNode;
    value?: TenantContextValue;
}

function TenantProvider(props: TenantProviderProps) {
    const [loading, setLoadingState] = useState(false),
        setIdle = useCallback(() => setLoadingState(false), []),
        setLoading = useCallback(() => setLoadingState(true), []);

    const [token, setToken] = useTenantToken(),
        removeToken = useCallback(() => setToken(null), [setToken]);

    const [tenantName, setTenantName] = useTenantName(),
        clearTenant = useCallback(() => setTenantName(null), [setTenantName]);

    const [authenticatedApolloClient] = useAuthenticatedClient();

    const getTenant = useCallback(async () => {
        return authenticatedApolloClient.query({
            query: GetTenantByNameDocument,
            variables: {
                name: tenantName,
            },
        });
    }, [authenticatedApolloClient, tenantName]);

    const tokenIsExpired = useCallback(
        (minValidity: number = 5): boolean => {
            if (token) {
                const decodedToken = jwtDecode<{ exp: number }>(token);

                const now = Date.now();
                const expires = decodedToken.exp;
                const threshold = Math.floor((now - minValidity * 1000) / 1000);
                return expires <= threshold;
            }

            return true;
        },
        [token],
    );

    const getToken = useCallback(async () => {
        if (!tenantName) {
            return;
        }

        if (!tokenIsExpired()) {
            return token;
        }

        setLoading();

        try {
            const response =
                await authenticatedApolloClient.mutate<IssueTenantTokenMutation>(
                    {
                        mutation: IssueTenantTokenDocument,
                        variables: {
                            tenant: tenantName,
                        },
                    },
                );

            const newToken = response?.data?.issueTenantToken?.token ?? null;

            setToken(newToken);

            return newToken;
        } catch (error) {
            console.error("Failed to get tenant token", error);
            removeToken();
        } finally {
            setIdle();
        }
    }, [
        authenticatedApolloClient,
        removeToken,
        setIdle,
        setLoading,
        setToken,
        tenantName,
        token,
        tokenIsExpired,
    ]);

    const refreshToken = useCallback(
        async (minValidity: number = 5): Promise<any> => {
            if (!tokenIsExpired(minValidity)) {
                return token;
            }

            return await getToken();
        },
        [tokenIsExpired, getToken, token],
    );

    useInterval(() => {
        tokenIsExpired();
    }, 1000);

    useEffect(() => {
        if (tenantName) {
            getToken().catch((error) => console.error(error));
        }
    }, [getToken, tenantName]);

    const value = {
        ...props.value,
        loading,
        token,
        getToken,
        removeToken,
        tenantName,
        clearTenantName: clearTenant,
        refreshToken,
        setTenantName,
        getTenant,
    };

    return (
        <TenantContext.Provider value={value}>
            {props.children}
        </TenantContext.Provider>
    );
}

export default TenantProvider;
