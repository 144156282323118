import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import MuiTabs from "@mui/material/Tabs";
import { Link, matchPath, useLocation } from "react-router-dom";

export interface TabsProps {
    ariaLabel?: string;
    tabs: { label: string; value: string; href: string }[];
}

export function Tabs({ tabs = [] }: TabsProps) {
    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2, mt: 1 }}>
                <TabsList tabs={tabs} />
            </Box>
        </Box>
    );
}

function useRouteMatch(patterns: readonly string[]) {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
        const pattern = patterns[i];
        const possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            return possibleMatch;
        }
    }

    return null;
}

function TabsList({
    tabs = [],
}: {
    tabs: { label: string; value: string; href: string }[];
}) {
    const routeMatch = useRouteMatch(tabs.map(({ href }) => href));
    const currentTab = routeMatch?.pattern?.path;

    return (
        <MuiTabs value={currentTab}>
            {tabs.map(({ label, href }) => (
                <Tab
                    key={href}
                    label={label}
                    value={href}
                    to={href}
                    component={Link}
                />
            ))}
        </MuiTabs>
    );
}

export default Tabs;
