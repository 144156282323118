import { useContext } from "react";
import TenantContext from "./TenantContext";

export function useTenant() {
    const tenant = useContext(TenantContext);

    if (tenant == null) {
        throw new Error("useTenant must be used within a TenantProvider");
    }

    return tenant;
}

export default useTenant;
