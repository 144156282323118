import { createContext } from "react";

export interface TenantContextValue {
    loading?: boolean;
    token?: string | null;
    getToken: () => Promise<any>;
    tenantName?: string | null;
    removeToken: () => void;
    clearTenantName: () => void;
    setTenantName: (tenantName: string) => void;
    getTenant: () => Promise<any>;
    refreshToken: (minValidity?: number) => Promise<any>;
}

const TenantContext = createContext<TenantContextValue | null>(null);

export default TenantContext;
