import Tabs from "@/components/organisms/Tabs";
import { useTranslation } from "react-i18next";

export function GeneralTabs() {
    const { t } = useTranslation("translation", {
        keyPrefix: "pages.General.GeneralTabs",
    });

    return (
        <Tabs
            tabs={[
                {
                    label: t("usersTab.label"),
                    href: "/installation/general-settings",
                    value: "users",
                },
                {
                    label: t("themeTab.label"),
                    href: "/installation/general-settings/theme",
                    value: "theme",
                },
                {
                    label: t("registrationTab.label"),
                    href: "/installation/general-settings/registration",
                    value: "registration",
                },
            ]}
        />
    );
}

export default GeneralTabs;
